import React from 'react'

import contactPage from '../img/contactPage.jpg'
import axios from 'axios'
import Layout from '../components/layout'

class Contact extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      successMessage: '',
      errorMessage: '',
      formFields: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
    }
  }

  render() {
    return (
      <Layout>
        <div>
          <div id="contactForm" className="box">

            <h2 className="stripe">Email Us</h2>

            <p>{this.state.successMessage}</p>
            <p style={{ color: 'red' }}>{this.state.errorMessage}</p>

            <form onSubmit={this.onFormSubmit}>
              <dl>
                <dt><label htmlFor="name">Name *</label></dt>
                <dd>
                  <input type="text" name="name" id="name"
                         value={this.state.formFields.name}
                         onChange={this.inputChangeHandler}
                  />
                </dd>

                <dt><label htmlFor="email">Email *</label></dt>
                <dd>
                  <input type="text" name="email" id="email"
                         value={this.state.formFields.email}
                         onChange={this.inputChangeHandler}
                  />
                </dd>

                <dt><label htmlFor="phone">Phone </label></dt>
                <dd>
                  <input type="text" name="phone" id="phone"
                         value={this.state.formFields.phone}
                         onChange={this.inputChangeHandler}
                  />
                </dd>

                <dt><label htmlFor="message">Your Message *</label></dt>
                <dd>
                <textarea name="message" id="message" rows="5" cols="20"
                          value={this.state.formFields.message}
                          onChange={this.inputChangeHandler}
                />
                </dd>

                <dt></dt>
                <dd><input type="submit" name="submit" id="submit" value="Send"/></dd>
              </dl>
            </form>

            <br className="clear"/>
          </div>

          <div id="contactDetails" className="box">
            <h3 className="stripe">Physical Address</h3>
            <p>Ace High Plumbing <br/> 551 Leeston Dunsandel Road <br/> Leeston, 7682 <br/> Canterbury <br/> New Zealand
            </p>
          </div>

          <div id="phoneDetails" className="box">
            <h3 className="stripe">Phone</h3>
            <p>Cell: 021 454 864</p>
          </div>

          <br className="clear"/>

          <img src={contactPage} width="960" height="200"
               style={{ marginTop: '10px', boxShadow: '0 0 20px #333' }}/>
        </div>
      </Layout>
    )
  }

  inputChangeHandler = (e) => {
    let formFields = { ...this.state.formFields }
    formFields[e.target.name] = e.target.value

    this.setState({
      formFields,
    })
  }

  onFormSubmit = (e) => {
    e.preventDefault()

    let formData = new FormData()

    for (let key in this.state.formFields) {
      if (this.state.formFields.hasOwnProperty(key)) {
        formData.set(key, this.state.formFields[key])
      }
    }

    axios.post('/submit-contact-us', formData)
      .then((response) => {
        this.setState({
          successMessage: response.data,
          errorMessage: '',
          formFields: {
            name: '',
            email: '',
            phone: '',
            message: '',
          },
        })
      })
      .catch((error) => {
        let message = 'An unknown error occurred, please try again.'

        if (error.response && error.response.data) {
          message = error.response.data
        }

        this.setState({
          successMessage: '',
          errorMessage: message,
        })
        console.error(error)
      })
  }
}

export default Contact
